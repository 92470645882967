import React from "react";

function Videos() {
  return (
    <div>
      <div className="2xl:my-10 my-4 ">
        <div className="flex flex-wrap justify-between w-full">
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/823818258?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            {" "}
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/848865333?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            {" "}
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/836848334?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/816142593?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            {" "}
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/805042141?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
          <div className="xl:w-[31%] md:w-[48%] w-full my-3 sm:my-4 xl:my-6">
            {" "}
            <div className="p-4 relative w-full sm:h-[300px] min-h-[250px] object-cover">
              <iframe
                src="https://player.vimeo.com/video/823970576?"
                className="absolute top-0 left-0 w-full  h-full "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Videos;
