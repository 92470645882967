import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { TfiVimeoAlt } from "react-icons/tfi";
import { RiFacebookFill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";
function Footer() {
  return (
    <>
      <div className="2xl:px-20 lg:px-10 sm:px-5 px-3  bg-black  text-center py-6 2xl:py-8">
        <div className="flex flex-col justify-center items-center ">
          <div className="flex items-center gap-x-5 mb-3">
            <a
              href="https://instagram.com/supernovamproductions?igshid=ZDdkNTZiNTM="
              target="_blank"
              rel="noreferrer"
              className="bg-white w-[40px] sm:w-[50px] h-[40px] sm:h-[50px] rounded-full text-md sm:text-lg flex flex-col  justify-center items-center transition-all duration-300 ease-in-out hover:bg-gold"
            >
              <AiFillInstagram />
            </a>{" "}
            <a
              href="https://vimeo.com/user79058762"
              target="_blank"
              rel="noreferrer"
              className="bg-white w-[40px] sm:w-[50px] h-[40px] sm:h-[50px] rounded-full text-md sm:text-lg flex flex-col  justify-center items-center transition-all duration-300 ease-in-out hover:bg-gold"
            >
              <TfiVimeoAlt />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090392265021&is_tour_dismissed=true"
              target="_blank"
              rel="noreferrer"
              className="bg-white w-[40px] sm:w-[50px] h-[40px] sm:h-[50px] rounded-full text-md sm:text-lg flex flex-col  justify-center items-center transition-all duration-300 ease-in-out hover:bg-gold"
            >
              <RiFacebookFill />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090392265021&is_tour_dismissed=true"
              target="_blank"
              rel="noreferrer"
              className="bg-white w-[40px] sm:w-[50px] h-[40px] sm:h-[50px] rounded-full text-md sm:text-lg flex flex-col  justify-center items-center transition-all duration-300 ease-in-out hover:bg-gold"
            >
              <AiFillYoutube />
            </a>
          </div>
          <p className="text-white lg:text-md text-sm">
            &#169; 2023 All Rights Reserved | Supernova Media Productions
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
