import "./App.css";
import HomePage from "./componants/pages/HomePage";

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
