import React, { useState } from "react";
import Videos from "./Videos";
import Brand from "../common/Video/Brand";
import Corporate from "../common/Video/Corporate";
import Events from "../common/Video/Events";
import Products from "../common/Video/Products";
import Documentary from "../common/Video/Documentary";
function VideoPortfolio() {
  const [active, setActive] = useState("All");

  return (
    <>
      <div
        className="2xl:px-20 lg:px-10 sm:px-5 px-3  w-full text-white   mx-auto  my-20 "
        id="work"
      >
        <h1 className="text-center font-semibold text-white text-lg sm:text-xl  lg:text-3xl 2xl:text-4xl  ">
          VIDEO PORTFOLIO
        </h1>
        <div className="  md:justify-around items-center   gap-x-8  whitespace-nowrap overflow-x-auto w-full mt-5  px-4 md:px-0 hidden-scroller hidden md:flex ">
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "All" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("All")}
          >
            All{" "}
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Corporate" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Corporate")}
          >
            Corporate / Real Estate
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Brand" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Brand")}
          >
            Brand / Design
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Events" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Events")}
          >
            Events
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Products" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Products")}
          >
            Products / Ad Films
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Documentary" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Documentary")}
          >
            Documentary
          </button>
        </div>
        {active === "All" && <Videos />}{" "}
        {active === "Corporate" && <Corporate />}{" "}
        {active === "Brand" && <Brand />} {active === "Events" && <Events />}{" "}
        {active === "Products" && <Products />}{" "}
        {active === "Documentary" && <Documentary />}
        <div className="flex justify-center ">
          <a href="https://vimeo.com/user79058762">
            <button className="border-white border  rounded-3xl py-2 px-5 text-white sm:text-base  lg:text-xmd     font-semibold transition-all duration-300 ease-in-out hover:text-gold hover:border-gold">
              Show Me More
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default VideoPortfolio;
