import React, { useState } from "react";
import Photos from "./Photos";
import Brand from "../common/Photo/Brand";
import Corporate from "../common/Photo/Corporate";
import Events from "../common/Photo/Events";
import Products from "../common/Photo/Products";
import Arial from "../common/Photo/Arial";

function PhotographyPortfolio() {
  const [active, setActive] = useState("All");

  return (
    <>
      <div className="md:px-0 sm:px-5 px-3  w-full text-white   mx-auto pt-12  mt-20 pb-10  border-t border-white">
        <h1 className="text-center font-semibold text-white text-lg sm:text-xl  lg:text-3xl 2xl:text-4xl  ">
          PHOTOGRAPHY PORTFOLIO
        </h1>
        <div className="  md:justify-around items-center   gap-x-8  whitespace-nowrap overflow-x-auto w-full mt-5  px-4 md:px-0 hidden-scroller hidden md:flex ">
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "All" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("All")}
          >
            All{" "}
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Corporate" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Corporate")}
          >
            Corporate / Real Estate
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Brand" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Brand")}
          >
            Brand / Design
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Events" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Events")}
          >
            Events
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Products" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Products")}
          >
            Products / Ad Films
          </button>{" "}
          <button
            className={`  lg:text-base text-sm   hover:text-gold  transition duration-300 ease-in-out     ${
              active === "Arial" ? " text-gold " : " text-white   "
            }`}
            onClick={() => setActive("Arial")}
          >
            Arial
          </button>
        </div>
        {active === "All" && <Photos />}
        {active === "Corporate" && <Corporate />}
        {active === "Brand" && <Brand />}
        {active === "Events" && <Events />}
        {active === "Products" && <Products />}
        {active === "Arial" && <Arial />}
      </div>
    </>
  );
}

export default PhotographyPortfolio;
