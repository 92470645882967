import React from "react";
import corporate2 from "../assets/png/corporate2.jpeg";
import Digtal from "../assets/png/digital.jpeg";
import product from "../assets/png/product.jpeg";
import Designing from "../assets/png/design.jpeg";
import Events from "../assets/png/event.jpeg";
import Documentary from "../assets/png/shorts.jpeg";
function OurServices() {
  return (
    <>
      <div className="2xl:px-20 lg:px-10 sm:px-5 px-3  bg-white" id="Services">
        <div className="  md:py-10 py-8">
          <h1 className="text-center text-black 2xl:text-5xl xl:text-4xl md:text-3xl text-lg font-semibold  ">
            Our Services
          </h1>
          <div className="flex flex-wrap py-8 justify-between ">
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={corporate2}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Corporate / Real Estate
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Corporate / Real Estate
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    We create corporate videos and photos that showcase your
                    brand and highlight your products and services. <br /> Our
                    team of skilled professionals can create content that
                    captures the essence of your brand and resonates with your
                    audience. With high-quality production values, engaging
                    visuals, and a well-written script, your corporate video and
                    photos can establish your company's credibility, increase
                    brand awareness, and drive engagement with your target
                    audience. From concept to final delivery, we work closely
                    with our clients to ensure their vision is brought to life.
                  </p>
                </div>
              </div>
            </div>{" "}
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={Digtal}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Branding / Digital Content
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Branding / Digital Content
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    As a company that specializes in creating digital content
                    for brands, we are committed to helping businesses
                    effectively communicate their message in the online space.
                    Whether it's creating content for your website, social
                    media, or email marketing campaigns or producing videos for
                    your digital channels with our expertise in digital
                    marketing and content creation, we can help you build a
                    strong online presence, increase brand awareness with your
                    target audience in order to achieve your marketing goals
                    with compelling and effective digital content.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={product}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Product Photography
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Product Photography
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    Our product photography services capture the essence of your
                    brand and products, driving sales and enhancing your brand
                    identity. We use the latest equipment and techniques to
                    create stunning images for your e-commerce, social media,
                    and print marketing needs. Our services include styling, set
                    design, and post-production editing to deliver high-quality
                    images that engage customers and boost sales. Let us help
                    you showcase your products with attention to detail and
                    creativity.
                  </p>
                </div>
              </div>
            </div>{" "}
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={Designing}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Designing / Printing
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Designing / Printing
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    Our design and printing services create visually stunning
                    marketing materials that make an impact. Our team works
                    closely with clients to understand their objectives and
                    deliver designs that effectively promote their brand. We
                    offer a wide range of services, including logo design,
                    business cards, brochures, flyers, and banners. Whether it's
                    for a trade show, marketing campaign, or general promotion,
                    we can help you achieve your goals with high-quality prints
                    that effectively communicate your message.
                  </p>
                </div>
              </div>
            </div>{" "}
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={Events}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Events
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Events
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    Our event photography and videography services capture the
                    essence of your special moments, whether it's a corporate
                    event, celebration, or live event. Our skilled team
                    documents every aspect of your event, including candid
                    shots, portraits, group shots, and branding details. Our
                    videography services cover venue footage, speeches,
                    highlights, and testimonials, creating a professionally
                    produced video. With our attention to detail and creativity,
                    we help you relive and share the memories of your special
                    event with loved ones and colleagues.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-[32%] sm:w-[48%] w-full my-4 ">
              <div className="card shadow-[0px_10px_20px] shadow-[#0000002f] rounded-[10px]  relative  overflow-hidden">
                <div className="front-content transition-all duration-300 ease-in-out overflow-hidden h-[500px] sm:h-[590px] md:max-h-[510px] lg:h-[590px] xl:max-h-[550px] 2xl:max-h-[550px] rounded-[10px] relative flex flex-col justify-center items-center ">
                  <img
                    src={Documentary}
                    alt=""
                    width="450"
                    height="450"
                    className=" object-cover w-full  rounded-[10px] h-full"
                  />
                  <p className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center absolute  ">
                    Short Films / Documentary
                  </p>
                </div>
                <div className="content">
                  <span className="text-white 2xl:text-xl  xl:text-lg text-md font-bold text-center  ">
                    Short Films / Documentary
                  </span>
                  <p className="text-sm xl:text-base   text-center ">
                    We specialize in creating engaging and impactful films that
                    connect with audiences on an emotional level. Our
                    experienced team works closely with clients to understand
                    their vision and deliver high-quality films. We offer
                    services such as script development, planning, filming,
                    editing, and post-production. Whether it's for a brand
                    campaign, non-profit documentary, or personal project, we
                    bring your vision to life with creativity and storytelling.
                    Let us capture your audience's attention and leave a lasting
                    impression through our short films and documentaries.
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default OurServices;
