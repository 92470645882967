import React from "react";

function AboutUs() {
  return (
    <>
      <div className="min-h-[100vh] about-bg w-full flex flex-col justify-center items-center">
        <div className="2xl:px-20 lg:px-10 sm:px-5 px-3  w-full text-white max-w-[957px] mx-auto sm:mt-60 mt-72  mb-6     ">
          <h1 className="text-center font-semibold lg:text-4xl sm:text-xl text-md2 pt-16 sm:pt-0">
            About us
          </h1>
          <p className="text-center font-semibold lg:text-md sm:text-xmd text-sm text-railway">
            {" "}
            We Are A Reputable Video Production Company Dedicated To The
            Creation Of Compelling Visual Content For Corporate, Commercial And
            Entertainment Endevors. We Are Fully Equipped To Handle All Your
            Needs Professionally With Particular Attention To Detail And
            Quality! We Have Strong, Healthy Relationships With Marketing
            Companies, Event Planner, Hotels And Conference Centers Which Serve
            Both The Private And Public Sectors. We Pride Ourselves In Having A
            Fresh, Innovative Approach, Resulting In Delivering A World Class
            Experience With Every Project We Execute.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
