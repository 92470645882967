import React from "react";
import { Parallax } from "react-parallax";
import Fishman from "../assets/png/FISHERMAN.png";

function VideoEngage() {
  return (
    <>
      <div>
        <Parallax
          bgImage={Fishman}
          strength={300}
          className="h-full min-h-[70vh] relative flex flex-col  justify-center  object-cover  "
        >
          <div className="     flex  justify-center  ">
            <div className="sm:flex   text-center    ">
              <h1 className=" 3xl:text-8xl 2xl:text-7xl   xl:text-5xl lg:text-3xl md:text-xl  text-lg text-white font-sans font-bold">
                "Videos That
              </h1>
              <h1 className=" 3xl:text-8xl 2xl:text-7xl   xl:text-5xl lg:text-3xl md:text-xl  text-lg text-white font-sans font-bold border-text">
                Engage
              </h1>
              <h1 className=" 3xl:text-8xl 2xl:text-7xl   xl:text-5xl lg:text-3xl md:text-xl  text-lg text-white font-sans font-bold">
                , Stories That
              </h1>
              <h1 className=" 3xl:text-8xl 2xl:text-7xl   xl:text-5xl lg:text-3xl md:text-xl  text-lg text-white font-sans font-bold border-text">
                {" "}
                Sell"
              </h1>
            </div>
          </div>
        </Parallax>
      </div>
    </>
  );
}

export default VideoEngage;
