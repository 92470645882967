import React from "react";

function Navbar() {
  return (
    <>
      {" "}
      <div className="  ">
        <div className="2xl:px-20 lg:px-10 sm:px-5 px-3    ">
          <div className="flex sm:flex-row flex-col items-center justify-between sm:py-4 py-3 bg-black bg-opacity-0           left-0 top-0 w-full ">
            <a
              href=""
              className="xl:text-md2 2xl:text-lg md:text-md  text-base text-white "
            >
              <span className=" font-bold tracking-[3px]    ">SUPERNOVA</span>
              <span className="font-light  ">MEDIA PRODUCTION</span>
            </a>

            <ul className="flex items-center gap-x-4 xl:gap-x-10 mt-2 sm:mt-0">
              <li className="sm:text-sm lg:text-base xl:text-xmd text-xs text-white">
                <a
                  href="#Services"
                  className="transition-all duration-300 ease-in-out hover:text-gold "
                >
                  Services
                </a>
              </li>{" "}
              <li className="sm:text-sm lg:text-base xl:text-xmd text-xs text-white">
                <a
                  href="#work"
                  className="transition-all duration-300 ease-in-out hover:text-gold "
                >
                  Our Work
                </a>
              </li>{" "}
              <li className="sm:text-sm lg:text-base xl:text-xmd text-xs text-white">
                <a
                  href="#Contact"
                  className="transition-all duration-300 ease-in-out hover:text-gold "
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
