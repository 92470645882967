import React from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Hero from "../sections/Hero";
import AboutUs from "../sections/AboutUs";
import OurServices from "../sections/OurServices";
import VideoEngage from "../sections/VideoEngage";
import VideoPortfolio from "../sections/VideoPortfolio";
import PhotographyPortfolio from "../sections/PhotographyPortfolio";
import LetsWork from "../sections/LetsWork";

function HomePage() {
  return (
    <>
      <Navbar />
      <Hero />
      <AboutUs />
      <OurServices />
      <VideoEngage />
      <VideoPortfolio />
      <PhotographyPortfolio />
      <LetsWork />
      <Footer />
    </>
  );
}

export default HomePage;
