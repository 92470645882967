import React from "react";
import C1image1 from "../../assets/png/c1image1.jpg";
import C1image2 from "../../assets/png/c1image2.jpg";
import C1image3 from "../../assets/png/c1image3.jpg";
import C1image4 from "../../assets/png/c1image4new.jpeg";
import C1image5 from "../../assets/png/c1image5.jpg";
import C1image6 from "../../assets/png/c1image6.jpg";
import C1image7 from "../../assets/png/c1image7.jpg";
import C1image8 from "../../assets/png/c1image8new.jpeg";
import C1image9 from "../../assets/png/c1image9.jpg";
import C2image1 from "../../assets/png/c2image1.png";
import C2image2 from "../../assets/png/c2image2.jpeg";
import C2image3 from "../../assets/png/c2image3.jpeg";
import C2image4 from "../../assets/png/c2image4.png";
import C2image5 from "../../assets/png/c2image5.jpeg";
import C2image6 from "../../assets/png/c2image6.jpeg";
import C3image1 from "../../assets/png/c3image1.jpeg";
import C3image2 from "../../assets/png/c3image2.jpeg";
import C3image3 from "../../assets/png/c3image3.jpeg";
import C3image4 from "../../assets/png/c3image4.jpeg";
import C3image5 from "../../assets/png/c3image5.jpeg";
import C3image6 from "../../assets/png/c3image6.jpeg";
import C3image7 from "../../assets/png/c3image7.jpeg";
import C3image8 from "../../assets/png/c3image8.jpeg";
import C3image9 from "../../assets/png/c3image9.jpeg";
import C4image1 from "../../assets/png/c4image1.jpeg";
import C4image2 from "../../assets/png/c4image2.jpeg";
import C4image3 from "../../assets/png/c4image3.jpeg";
import C4image4 from "../../assets/png/c4image4.jpeg";
import C4image5 from "../../assets/png/c4image5.jpeg";
import C4image6 from "../../assets/png/c4image6.jpeg";
import C5image1 from "../../assets/png/c5image1.jpeg";
import C5image2 from "../../assets/png/c5image2.jpeg";
import C5image3 from "../../assets/png/c5image3.jpeg";
import C5image4 from "../../assets/png/c5image4.jpeg";
import C5image5 from "../../assets/png/c5image5.jpeg";
import C5image6 from "../../assets/png/c5image6.jpeg";
import C5image7 from "../../assets/png/c5image7.jpeg";
import C5image8 from "../../assets/png/c5image8.jpeg";
import C5image9 from "../../assets/png/c5image9.jpeg";

function Brand() {
  return (
    <div>
      <div className="2xl:mt-10 my-4 ">
        <div className="flex flex-wrap justify-between w-full">
          {" "}
          {/* row 2 */}
          <div className="xl:w-[30%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C2image1}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          <div className="xl:w-[40%] lg:w-1/2 w-full  ">
            <div className="flex h-[210px]  overflow-hidden ">
              <img
                src={C2image2}
                alt=""
                width="285"
                height="285"
                className="w-1/2  object-cover p-1 
                  "
              />

              <img
                src={C2image4}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex h-[210px] overflow-hidden ">
              <img
                src={C2image3}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C2image5}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div>
          <div className="xl:w-[30%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C2image6}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          {/* row 1 */}
          {/* <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex  h-[210px]   overflow-hidden ">
              <img
                src={C1image1}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C1image2}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex  h-[210px]  overflow-hidden ">
              <img
                src={C1image3}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C1image4}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div>
          <div className="xl:w-[46%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C1image5}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex h-[210px]  overflow-hidden ">
              <img
                src={C1image6}
                alt=""
                width="285"
                height="285"
                className="w-1/2  object-cover p-1 
                  "
              />

              <img
                src={C1image8}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex h-[210px] overflow-hidden ">
              <img
                src={C1image7}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C1image9}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div> */}
          {/* row 3 */}
          {/* <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex  h-[210px]   overflow-hidden ">
              <img
                src={C3image1}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C3image3}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex  h-[210px]  overflow-hidden ">
              <img
                src={C3image2}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C3image4}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div>
          <div className="xl:w-[46%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C3image5}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex h-[210px]  overflow-hidden ">
              <img
                src={C3image6}
                alt=""
                width="285"
                height="285"
                className="w-1/2  object-cover p-1 
                  "
              />

              <img
                src={C3image8}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex h-[210px] overflow-hidden ">
              <img
                src={C3image7}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C3image9}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div> */}
          {/* row 4 */}
          {/* <div className="xl:w-[30%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C4image1}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          <div className="xl:w-[40%] lg:w-1/2 w-full  ">
            <div className="flex h-[210px]  overflow-hidden ">
              <img
                src={C4image2}
                alt=""
                width="285"
                height="285"
                className="w-1/2  object-cover p-1 
                  "
              />

              <img
                src={C4image4}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex h-[210px] overflow-hidden ">
              <img
                src={C4image3}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C4image5}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div>
          <div className="xl:w-[30%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C4image6}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div> */}
          {/* row 5 */}
          {/* <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex  h-[210px]   overflow-hidden ">
              <img
                src={C5image1}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C5image3}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex  h-[210px]  overflow-hidden ">
              <img
                src={C5image2}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C5image4}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div>
          <div className="xl:w-[46%] lg:w-1/2 w-full overflow-hidden">
            <div className="w-full h-[420px] overflow-hidden ">
              <img
                src={C5image5}
                alt=""
                width="1000"
                className="w-full object-cover p-1 h-full 
                  "
              />
            </div>
          </div>
          <div className="xl:w-[27%] lg:w-1/2 w-full  ">
            <div className="flex h-[210px]  overflow-hidden ">
              <img
                src={C5image6}
                alt=""
                width="285"
                height="285"
                className="w-1/2  object-cover p-1 
                  "
              />

              <img
                src={C5image8}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover  p-1
                  "
              />
            </div>
            <div className="flex h-[210px] overflow-hidden ">
              <img
                src={C5image7}
                alt=""
                width="285"
                height="285"
                className="w-1/2 object-cover p-1 
                  "
              />

              <img
                src={C5image9}
                alt=""
                width="295"
                height="295"
                className="w-1/2 p-1  object-cover   
                  "
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Brand;
