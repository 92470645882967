import React from "react";
import Herovideo from "../assets/videos/hero.mp4";
function Hero() {
  return (
    <>
      <div className="h-full w-full relative flex flex-col  justify-center items-center overflow-hidden ">
        <video autoPlay muted loop className="w-full  h-[93vh] object-cover   ">
          <source src={Herovideo} type="video/mp4" />0
        </video>
        <div className=" absolute text-center    sm:-mt-20 ">
          <h1 className=" text-white 3xl:text-[360px]  tracking-[3px] lg:tracking-normal text-[17vw]  lg:text-[18vw] font-black text-center leading-[105%]">
            supernova
          </h1>
          <span className="font-moontime text-gold sm:text-[5vw] text-md  text-center   ">
            Illuminating your Brand
          </span>
        </div>
      </div>
    </>
  );
}

export default Hero;
