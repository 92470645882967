import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { BsPhone } from "react-icons/bs";
import { Ripple, Input, initTE } from "tw-elements";
initTE({ Ripple, Input });

function LetsWork() {
  return (
    <div
      className="2xl:px-20 lg:px-10 sm:px-5 px-3  w-full bg-white  py-2 mx-auto  "
      id="Contact"
    >
      <div className="lg:py-24  sm:py-10">
        <div className="flex flex-wrap justify-between">
          <div className="my-10 lg:w-1/2 w-full">
            <div>
              <h2 className="text-black  xl:text-[70px] md:text-6xl sm:text-4xl text-xl font-semibold leading-[100%] font-satisfies ">
                Let's Work
              </h2>
              <h2 className="over-text-line     xl:text-[70px] md:text-6xl sm:text-4xl text-xl font-semibold   font-satisfies  ">
                Together
              </h2>
              <p className=" text-black  md:text-md text-base my-4 xl:pe-12  ">
                Looking for a professional media production team? Look no
                further. Our team delivers high-quality productions that exceed
                expectations. We handle everything from pre-production planning
                to final editing for a seamless and successful production.
                Contact us to discuss your project and achieve your goals today!
              </p>
              <div className="flex flex-wrap items-center ">
                <div className="flex items-center me-4">
                  <HiOutlineMail className="sm:text-md lg:text-[30px] text-black me-2  " />
                  <a
                    href="mailto:supernovamproductions@gmail.com"
                    className="text-[17px] sm:text-md 2xl:text-[28px] mb-2 text-blue  "
                  >
                    supernovamproductions@gmail.com
                  </a>{" "}
                </div>
                <div className="flex items-center">
                  <BsPhone className="sm:text-md lg:text-[30px] text-black me-2  " />
                  <a
                    href="tel:9818497475"
                    className="text-[17px] sm:text-md 2xl:text-[28px] mb-2 text-blue  "
                  >
                    {" "}
                    +91 882-683-3059
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="my-10 lg:w-1/2 w-full ">
            <div className="max-w-[521px] lg:pl-8 2xl:max-w-[621px] lg:ml-auto  mx-auto  ">
              <form action="https://formspree.io/f/xlekveav" method="POST">
                <div class="input-with-placeholder w-full relative border border-[(0_0_0_.2)] rounded-md sm:mb-6 mb-4">
                  <input
                    type="text"
                    id="username"
                    className="w-full 2xl:h-[50px] h-[40px] px-4   focus-visible:outline-none rounded-md "
                    required
                  />
                  <label htmlFor="username" className=" ">
                    Name
                  </label>
                </div>{" "}
                <div class="input-with-placeholder w-full relative border border-[(0_0_0_.2)] rounded-md sm:mb-6 mb-4">
                  <input
                    type="email"
                    id="email"
                    className="w-full 2xl:h-[50px] h-[40px] px-4   focus-visible:outline-none rounded-md "
                    required
                  />
                  <label htmlFor="email" className=" ">
                    Email
                  </label>
                </div>{" "}
                <div class="input-with-placeholder w-full relative border border-[(0_0_0_.2)] rounded-md sm:mb-6 mb-4">
                  <input
                    type="tel"
                    id="phone"
                    className="w-full 2xl:h-[50px] h-[40px] px-4   focus-visible:outline-none rounded-md "
                    required
                  />
                  <label htmlFor="email" className=" ">
                    Phone
                  </label>
                </div>
                <div class="input-with-placeholder textarea w-full relative border border-[(0_0_0_.2)] rounded-md sm:mb-6 mb-4">
                  <textarea
                    name="textarea"
                    id="textarea"
                    rows="4"
                    className="w-full   py-3 px-4   focus-visible:outline-none rounded-md "
                  ></textarea>
                  <label htmlFor="textarea" className=" ">
                    Your message
                  </label>
                </div>
                <div className="my-4">
                  <button
                    type="submit"
                    className="text-[17px] bg-[dodgerblue] text-white shadow-[2px_2px_4px] transition-all duration-300 ease-in-out hover:shadow-[rgba(0,0,0,0.4)] w-full rounded-md  py-3"
                  >
                    {" "}
                    Send Message{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetsWork;
